import { Text, Image, Flex } from "@chakra-ui/react";
import bgLogo from "../assets/Isolation_Mode.svg"
import "../assets/styles/home.css";
import { useEffect, useRef, useState } from "react";
import "../assets/styles/pulseeffect.css";
import SearchInput from "../components/SearchInput";
import arrowRight from "../assets/icons/arrow_forward.svg"
import HomeFooterLogo from "../assets/home-footer-logo.png"
import axios from "axios";
import { supabase } from "../api/supabase";

interface ISearchSuggestion {
    IP: string;
    CVE: string;
    Tag: string;
    Path: string;
}

export default function Home() {
    const [search, setSearch] = useState<string>("");
    const pulseRef = useRef<HTMLDivElement>(null);
    const [searchSuggestions, setSearchSuggestions] = useState<ISearchSuggestion | undefined>()
    const BASE_URL = process.env.REACT_APP_PROXY;

    const pulse = () => {
        if (pulseRef.current) {
            pulseRef.current.classList.remove('pulse');
            setTimeout(() => pulseRef.current?.classList.add('pulse'), 0); /// Move execution to the next tick
        }
    }

    useEffect(() => {
        (async() => {
            try {
                const session = supabase.auth.getSession();
                const accessToken = (await session).data.session?.access_token;
                if(!accessToken) return;

                axios.get(`${BASE_URL}/api/search-suggestions?`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`,
                        }
                    },).then(res => {
                        setSearchSuggestions(res.data);
                    })
            } catch (e) {
                return;
            }
        })()
    }, [])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            background: `url(${bgLogo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            height: "54%",
            backgroundSize: "contain"
        }}>
            <div className="content-wrapper">
                {/* <img src={sphLogo} style={{ margin: "0 auto" }} alt="" /> */}
                <SearchInput search={search} setSearch={setSearch} pulseRef={pulseRef} />
                <div>
                    <Text fontSize={{ sm: "15px" }} color="#A7A7A7">Search shortcuts:</Text>
                    <div className="suggestions-wrapper">
                        <div className="suggestion-wrapper" onClick={() => { setSearch(searchSuggestions ? searchSuggestions.IP : "185.224.128.10"); pulse() }}><Text fontSize='md'>IP</Text><Image src={arrowRight} /></div>
                        <div className="suggestion-wrapper" onClick={() => { setSearch(searchSuggestions ? searchSuggestions.CVE : "CVE-2023-1389"); pulse() }}><Text fontSize='md'>CVE</Text><Image src={arrowRight} /></div>
                        <div className="suggestion-wrapper" onClick={() => { setSearch(searchSuggestions ? searchSuggestions.Path : "/cgi-bin/luci/;stok=/locale"); pulse() }}><Text fontSize='md'>Path</Text><Image src={arrowRight} /></div>
                        <div className="suggestion-wrapper" onClick={() => { setSearch(searchSuggestions ? searchSuggestions.Tag : "Fortinet"); pulse() }}><Text fontSize='md'>Tag</Text><Image src={arrowRight} /></div>
                    </div>
                </div>
            </div>
            <Flex className="home-footer" alignItems={"center"} justifyContent={"space-between"} position="absolute" bottom="72px">
                <Image src={HomeFooterLogo} h={"48px"} />
                <Flex columnGap={"45px"}>
                    <Text fontSize={"12px"} color={"rgba(167, 167, 167, 1)"}>CONTACT SUPPORT</Text>
                    <Text fontSize={"12px"} color={"rgba(167, 167, 167, 1)"}>© 2025 Sphere by Alphatechs</Text>
                </Flex>
            </Flex>
        </div>
    );
}