import {
  Flex,
  Heading,
  Image
} from '@chakra-ui/react';
import Logo from "../assets/sphere.logo.x120.png";
import "../assets/styles/navbar.css";
import Login from './Login';
import { useEffect, useState } from 'react';
import Signup from './Signup';
import CustomButton from './buttons/CustomButton';
import { useAuthStore } from '../store/useAuthStore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchInput from './SearchInput';
import { supabase } from '../api/supabase';

export default function Navbar() {
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userData = useAuthStore((state) => state.user);
  const [search, setSearch] = useState<string>("");
  const navState = useLocation();
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout); // Access the login function from the store
  const [aal, setAal] = useState<string | undefined>(undefined)


  /// NOTE RW: This runs every time and checks if user email is already confirmed
  useEffect(() => {
    if (!isAuthenticated || !userData || !userData.identities || userData.identities.length === 0) return;
    const identity = userData.identities.filter((identity) => identity.provider === "email")[0];
    if (identity.identity_data && identity.identity_data.email_verified === false && (!navState.pathname.includes("reset-password") || !navState.pathname.includes("setup-mfa"))) {
      navigate('/reset-password');
    } else {
      supabase.auth.mfa.getAuthenticatorAssuranceLevel().then(assuranceLevel => {
        if (assuranceLevel.data?.nextLevel !== "aal2") {
          navigate("/setup-mfa");
        } else if(assuranceLevel.data?.nextLevel === "aal2" && assuranceLevel.data?.currentLevel === "aal1") {
          setLoginModal(true);
          setAal("aal1")
        }
      })
    }
  }, [isAuthenticated, navState.pathname])

  return (
    <Flex justifyContent={"center"} flexDir={"column"} alignItems={"center"}>
      <Flex className="nav-wrapper" justifyContent={"space-between"}>
        <Login open={loginModal} setOpen={setLoginModal} aal={aal} />
        <Signup open={signupModal} setOpen={setSignupModal} />

        <div className={"nav-links"}>
          <Link to="/"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Home</Heading></Link>
          {/* <Link to="/latest"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Latest</Heading></Link> */}
          {/* {isAuthenticated && <Link to="/reports"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Reports</Heading></Link>} */}
          {isAuthenticated && <Link to="/credentials"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Identity</Heading></Link>}
          {/* <Heading className={"nav-heading"} size="sm" fontWeight={400}>Threats</Heading> */}
        </div>

        <Flex ml="-12%" className={"trends"}>
          <Link to="/"><Image minWidth="34px" style={{ width: "60px", height: "60px" }} src={Logo} /></Link>
        </Flex>

        <Flex className={"buttons"}>
          <CustomButton display={isAuthenticated ? "none" : "block"} onClick={() => setLoginModal(true)} variant={"black"}>LOGIN</CustomButton>
          <CustomButton display={isAuthenticated ? "none" : "block"} onClick={() => setSignupModal(true)} variant={"red"}>SIGN UP</CustomButton>
          <CustomButton display={isAuthenticated ? "block" : "none"} onClick={() => navigate("/dashboard")} variant="red"><Link to="/dashboard">DASHBOARD</Link></CustomButton>
          <CustomButton display={isAuthenticated && !navState.pathname.includes("reset-password") ? "block" : "none"} variant="red" onClick={() => logout()}>LOGOUT</CustomButton>
        </Flex>
      </Flex>
      {(navState.pathname !== '/' && !navState.pathname.includes('/reports') && !navState.pathname.includes('/credentials') && !navState.pathname.includes('/combolist')) &&
        <Flex width="100%" justifyContent={"center"}><div style={{ marginRight: "12px", width: "60%" }}><SearchInput search={search} setSearch={setSearch} /></div></Flex>
      }
    </Flex>
  );
}